
import axios, { AxiosError } from 'axios';
import { IUserData } from "@/store/UsersStore";
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRouter } from "vue-router";
import { campHandleCPF } from '@/composables/DataValidation';
import { useUsersStore } from "@/store/UsersStore";
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedbackV2,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampConstrainProportionDiv
} from '@/components';
import modalRelationStore from './Components/modalRelationStore.vue';
import { Modal } from "bootstrap";
import { useLoaderStore} from "@/store/LoaderStore";
import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
import CampModalRecoverRegister from '@/components/CampModalRecoverRegister.vue';
import { CampUndefinedImgURL } from '@/components/CampModalCropImage';
import { useCompanyStore } from '@/store/CompanyStore';
import { useAuthStore } from '@/store/AuthStore';
import { useStoreStore } from '@/store/StoreStore';

export interface IUserResponse {
  data: {
    error: boolean,
    message: string,
    data: IUserData[]
    errorCode: string
  }
}

export default defineComponent({
  name: "UserList",
  components: {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh,
    modalRelationStore,
    CampModalDelRegister,
    CampModalRecoverRegister,
    CampConstrainProportionDiv
  },
  setup() {
    const router = useRouter();
    const loaderStore = useLoaderStore();
    const relationProps = ref(false)

    const queryObject = ref<{
      noError: boolean,
      data:  null | IUserData[]
    }>({
      noError: false,
      data: null
    })
    const isLoading = ref(false)
    const printUsers = ref<null | IUserData[]>(null)
    const keywordSearch = ref("")
    const companyStore = useCompanyStore()
    const authStore = useAuthStore()
    const storeStore = useStoreStore()

    const relationData = ref<IUserData | null>(null)

    let auxModal;

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    const usersStore = useUsersStore()
    function funcDetailRelationData(data) {
      openModal('myModalUser')
      relationProps.value = !relationProps.value
      relationData.value = data
    }

    async function handleGetUsers(queryParams = "") {
      isLoading.value = true
      try {
        const result: IUserResponse = await axios.get(`/api/getUserList${queryParams}`)
        const { data } = result
        if(!data.error) {
          queryObject.value.noError = true
          queryObject.value.data = data.data
          printUsers.value = data.data
          usersStore.setCompanies(data.data)
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        usersStore.setCompanies([])
      } finally {
        loaderStore.close()
        isLoading.value = false
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!queryObject.value.data)
        return true
      printUsers.value = queryObject.value.data.filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    onMounted(async () => {
      handleGetUsers()
    })

    const { showTimeAlert } = useAlert()
    const activeStatus = ref(true)
    const tableItem = ref<IUserData | null>(null)
    const toggleModalDelRegister = ref(false)
    function handleModalDelRegister(item: IUserData) {
      if(authStore.getUser.id == item.id)
        return showTimeAlert(`O registro ${item.name} está logado no momento!`, "error")
      toggleModalDelRegister.value = !toggleModalDelRegister.value
      tableItem.value = item
    }

    async function handleConfirmItemDel() {
      if(!tableItem.value)
        return
      try {
        loaderStore.open()
        const result = await axios.delete(`/api/deleteUser/${tableItem.value.id}`)
        showTimeAlert(`O registro ${tableItem.value.name} foi excluído com sucesso!`)
        handleGetUsers()
        tableItem.value = null
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        loaderStore.close()
      }
    }

    const toggleModalRecoverRegister = ref(false)
    function handleModalRecoverRegister(item: IUserData) {
      toggleModalRecoverRegister.value = !toggleModalRecoverRegister.value
      tableItem.value = item
    }
    async function handleConfirmItemRecover() {
      if(!tableItem.value)
        return
      try {
        loaderStore.open()
        const result = await axios.put(`/api/recoverUser/${tableItem.value.id}`)
        showTimeAlert(`O registro ${tableItem.value.name} foi recuperado com sucesso!`)
        if(Array.isArray(printUsers.value) && printUsers.value.length > 1)
          handleGetUsers("?only_inactive=1")
        else
          activeStatus.value = true
        tableItem.value = null
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        loaderStore.close()
      }
    }

    watch(() => companyStore.company, () => {
      handleGetUsers()
    })

    watch(() => storeStore.store, () => {
      handleGetUsers()
    })
    watch(() => storeStore.id, () => {
      isLoading.value = true
    })

    watch(() => companyStore.id, () => {
      isLoading.value = true
    })

    watch(() => activeStatus.value, () => {
      if(activeStatus.value)
        handleGetUsers("?only_inactive=0")
      else
        handleGetUsers("?only_inactive=1")
    })

    return {
      router,
      isLoading,
      printUsers,
      keywordSearch,
      queryObject,
      campHandleCPF,
      activeStatus,
      tableItem,
      toggleModalDelRegister,
      handleModalDelRegister,
      toggleModalRecoverRegister,
      handleModalRecoverRegister,
      handleConfirmItemDel,
      handleConfirmItemRecover,
      funcDetailRelationData,
      relationProps,
      relationData,
      openModal,
      CampUndefinedImgURL,
      authStore
    }
  }

})
